import React from 'react';
import { useSelector } from 'react-redux';

const ServicesSection = ({}) => {

    const services = useSelector(state => state.section.servicesSection);
    const items = useSelector(state => state.section.sectionItems);

    return ( 
        <div>
        <div className = "site-section border-bottom"
        id = "services-section">
        <div className = "container">
        <div className = "row justify-content-center">

        <div className = "col-md-7 text-center">
        <span className = "sub-title">{services.title}</span>
        <h2 className = "font-weight-bold text-black">{services.subtitle}</h2>
        <p className = "mb-5">
        {services.baseline}
        </p>
        </div>
        </div>

        <div className = "row">

            {
                items.map(item=>(
                <div className = "col-lg-4 col-md-6 mb-5">
                    <div className = "media custom-media">
                        <div className = "mr-3 icon">
                            <img className = "flaticon-interior-design display-4" src={item.image}/>
                        </div>
                        <div className = "media-body">
                        <h5 className = "mt-0">{item.label}</h5>
                        {item.description}<br/>
                        <a href={item.url} target="_blank">{item.url}</a>
                        </div>
                    </div>
                </div>
                ))
            }

        </div> </div> </div> </div>
    );
}

export default ServicesSection;