import React from 'react';
import { useSelector } from 'react-redux';

const Project = ({}) => {

    const items = useSelector(state => state.section.realisationsItems);

    return (
        <div>
        <div className = "row">

        {
            items.map((item, index)=>(
            <>
                <div className = "col-lg-4 col-md-6 mb-4 project-entry" key={item.id}>
                    <a href =""
                    className = "d-block figure"
                    data-fancybox = "gallery" >
                        <img src ={item.image}
                        alt = "Image"
                        className = "img-fluid" style={{minHeight:350,maxHeight:350,minWidth:'100%',maxWidth:'100%'}} />
                    </a> 
                    <h3 className = "mb-0">
                        < a href = "#">{item.label}</a>
                    </h3>
                    <span className = "text-muted">{item.description}</span>
                </div>
            </>
            ))
        }
        </div>
    </div> 

    );
}

export default Project;
