import { createSlice } from "@reduxjs/toolkit";

export const linkSlice = createSlice({
  name: "links",
  initialState:{
    links:new Array({})
  },
  reducers:{
    setLinks:(state, {payload})=>{
      console.log("setLinks, link.slice.js");
      console.log(payload);
      state.links = payload;
      console.log(state.links);
    },
  },
});

export const { setLinks } = linkSlice.actions;
export default linkSlice.reducer;
