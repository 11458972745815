import { createSlice } from "@reduxjs/toolkit";

export const sectionSlice = createSlice({
  name: "section",
  initialState:{
    servicesSection:{},
    sectionItems:[],
    realisationsSection:{},
    realisationsItems:[],
    sponsoringSection:{},
    sponsoringItems:[]
  },
  reducers:{
    setServicesSection:(state, {payload})=>{
      console.log("setServicesSection, section.slice.js");
      console.log(payload);
      state.servicesSection = payload.filter(s => s.id === 1)[0];
      console.log(state.servicesSection);
    },
    setSectionItems:(state, {payload})=>{
      console.log("setSectionItems, section.slice.js");
      console.log(payload);
      state.sectionItems = payload.filter(s => s.sectionid === 1);
      console.log(state.sectionItems);
    },
    setRealisationsSection:(state, {payload})=>{
      console.log("setRealisationsSection, section.slice.js");
      console.log(payload);
      state.realisationsSection = payload.filter(s => s.id === 2)[0];
      console.log(state.realisationsSection);
    },
    setRealisationsItems:(state, {payload})=>{
      console.log("setRealisationsItems, section.slice.js");
      console.log(payload);
      state.realisationsItems = payload.filter(s => s.sectionid === 2);
      console.log(state.realisationsItems);
    },
    setSponsoringSection:(state, {payload})=>{
      console.log("setSponsoringSection, section.slice.js");
      console.log(payload);
      state.sponsoringSection = payload.filter(s => s.id === 3)[0];
      console.log(state.sponsoringSection);
    },
    setSponsoringItems:(state, {payload})=>{
      console.log("setSponsoringItems, section.slice.js");
      console.log(payload);
      state.sponsoringItems = payload.filter(s => s.sectionid === 3);
      console.log(state.sponsoringItems);
    },
  },
});

export const { setServicesSection, setSectionItems, setRealisationsSection, setRealisationsItems, setSponsoringSection, setSponsoringItems } = sectionSlice.actions;
export default sectionSlice.reducer;
