import { createSlice } from "@reduxjs/toolkit";

export const aboutsectionSlice = createSlice({
  name: "aboutsection",
  initialState:{
    aboutsection:{}
  },
  reducers:{
    setAboutSection:(state, {payload})=>{
      console.log("setAboutSection, aboutsection.slice.js");
      console.log(payload);
      state.aboutsection = payload;
      console.log(state.aboutsection);
    },
  },
});

export const { setAboutSection } = aboutsectionSlice.actions;
export default aboutsectionSlice.reducer;
