import React from 'react';
import { useSelector } from 'react-redux';


const NewsSection = ({}) => {

    const sponsoring = useSelector(state => state.section.sponsoringSection);
    const items = useSelector(state => state.section.sponsoringItems);

    return ( 

        <div>
            <div className = "site-section border-bottom"
            id = "news-section">
                <div className = "container">
                    <div className = "row justify-content-center">
                        <div className = "col-md-7 text-center">
                        <span className = "sub-title">{sponsoring.title}</span>
                        <h2 className = "font-weight-bold text-black">{sponsoring.subtitle}</h2>
                        <p className = "mb-5">
                        {sponsoring.baseline}
                        </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className = "row mb-5">
            {
                items.map((item,index)=>(

                    <>
                        <div className = "col-lg-4 col-md-6 mb-4 mb-lg-0 post-entry" key={item.id}>
                                <a href = "#"
                                className = "d-block figure">
                                    <img src ={item.image}
                                    alt = "Image"
                                    className = "img-fluid" />
                                </a> 
                                <span className = "text-muted d-block mb-1">{item.description}</span>
                                <h3>
                                    < a href = "#">{item.label}</a>
                                </h3> 
                        </div>
                    </>

                ))
            }
            </div>       

        </div>
    );
}

export default NewsSection;
