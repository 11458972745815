import React from 'react';
import { useSelector } from 'react-redux';
import Url from '../Constants/Url';

const Overlay = ({}) => {

  const overlay = useSelector(state => state.overlay.overlay);
  const background = overlay.backgroundurl;


    return (
      <div>
        <div style = {{background: 'url(' + background + ')'}}
        className = "site-blocks-cover overlay"
        data-aos = "fade"
        data-stellar-background-ratio = "0.5"
        id = "home-section">

        <div className = "container">

        <div className = "row align-items-center text-center justify-content-center">
        <div className = "col-md-8">
        
        <a data-fancybox data-ratio = "2"
        href = {overlay.videourl} style={{justifyContent:'center',alignItems:'center'}}>
        <i class="bi bi-play-circle" style={{fontSize: '3rem',color:'white'}}> </i> </a> <h1 className = "text-uppercase"> {overlay.title} </h1>
        <h4 className = "text-uppercase"> {overlay.subtitle} </h4>
        <span className = "sub-text mb-3 d-block"> < em> {overlay.baseline} </em></span>
        </div> </div>
        </div>

        </div> </div>
    );
}


export default Overlay;
