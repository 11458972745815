import { createSlice } from "@reduxjs/toolkit";

export const overlaySlice = createSlice({
  name: "overlay",
  initialState:{
    overlay:{}
  },
  reducers:{
    setOverlay:(state, {payload})=>{
      console.log("setOverlay, overlay.slice.js");
      console.log(payload);
      state.overlay = payload;
      console.log(state.overlay);
    },
  },
});

export const { setOverlay } = overlaySlice.actions;
export default overlaySlice.reducer;
