import { createSlice } from "@reduxjs/toolkit";

export const headerSlice = createSlice({
  name: "header",
  initialState:{
    headerInfo:{}
  },
  reducers:{
    setHeaderInfo:(state, {payload})=>{
      console.log("setHeaderInfo, header.slice.js");
      console.log(payload);
      state.headerInfo = payload;
    },
  },
});

export const { setHeaderInfo } = headerSlice.actions;
export default headerSlice.reducer;
