import React from 'react';
import Project from './project';
import { useSelector } from 'react-redux';

const Projects = ({}) => {

    const realisations = useSelector(state => state.section.realisationsSection);


    return ( 
        <div>
        <div className = "site-section border-bottom"
        id = "realisations-section">
        <div className = "container">
        <div className = "row justify-content-center">
        <div className = "col-md-7 text-center">
        <span className = "sub-title">{realisations.title}</span>
        <h2 className = "font-weight-bold text-black">{realisations.subtitle}</h2>
        <p className = "mb-5">
        {realisations.baseline}
        </p>
        </div>
        </div>


        <Project />

        </div>
        </div>

        </div>
    );
}


export default Projects;