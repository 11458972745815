import React from 'react';
import { useSelector } from 'react-redux';
import 'bootstrap-icons/font/bootstrap-icons.css';

const Header = () => {
    const header = useSelector(state => state.header.headerInfo);
    const links = useSelector(state => state.links.links);
    const menus = useSelector(state => state.menus.menus);

    return (
        <div>
            <div class = "site-mobile-menu site-navbar-target" >
                <div class = "site-mobile-menu-header" >
                    <div class = "site-mobile-menu-close mt-3" >
                        
                        <i className = "bi bi-x-lg js-menu-toggle" 
                            style={{fontSize: '1.1rem',color:'black'}}></i>
                        
                    </div>
                </div>
                <div class = "site-mobile-menu-body" > </div>
            </div>
            {header !== undefined && menus !== undefined && links !== undefined ? (
                <>
                    
                    <div class = "site-navbar-wrap" >
           <div class = "site-navbar-top" >
              <div class = "container py-3" >
                 <div class = "row align-items-center" >
                    <div class = "col-6" >
                        {
                                            links.map((link)=>(
                                                <a key={link.id} target="_blank" href ={link.url} className = "p-2 pl-0">
                                                    <i className={link.icon} style={{fontSize: '1.1rem',color:'white'}}/>
                                                </a>
                                            ))
                        }
                    </div>
                    <div class = "col-6" >
                       <div class = "d-flex ml-auto" >
                                        <a href = "#" className = "d-flex align-items-center ml-auto mr-4">
                                            <i className ="bi bi-envelope " style={{fontSize: '1.1rem',color:'white'}}/>&nbsp;&nbsp;
                                            <span className = "d-none d-md-inline-block">{header.email}</span>
                                        </a>
                                        <a href = "#" className = "d-flex align-items-center">
                                            <i className ="bi bi-telephone " style={{fontSize: '1.1rem',color:'white'}}/> &nbsp;&nbsp;
                                            <span className = "d-none d-md-inline-block">{header.phonenumber}</span>
                                        </a>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
           <div class = "site-navbar site-navbar-target js-sticky-header" >
              <div class = "container" >
                 <div class = "row align-items-center" >
                    <div class = "col-2" >
                       <h1 class = "my-0 site-logo" > <a href = "index.html" > AREUS </a></h1>
                    </div>
                    <div class = "col-10" >
                       <nav class = "site-navigation text-right"
                          role = "navigation" >
                          <div class = "container" >
                             <div class = "d-inline-block d-lg-none ml-md-0 mr-auto py-3" > <a href = "#"
                                class = "site-menu-toggle js-menu-toggle " > 
                                                <i className = "bi bi-list" style={{fontSize: '2rem',color:'white'}}> </i>

                                </a > </div>
                             <ul class = "site-menu main-menu js-clone-nav d-none d-lg-block" >
                                
                                <li > <a href = "#about-section"
                                   class = "nav-link" > A propos </a></li >
                                <li > <a href = "#services-section"
                                   class = "nav-link" > Services </a></li >
                                <li > <a href = "#realisations-section"
                                   class = "nav-link" > Réalisations </a></li >
                                <li > <a href = "#news-section"
                                   class = "nav-link" > Actualités </a></li >
                                <li > <a href = "#contact-section"
                                   class = "nav-link" > Contact </a></li >
                             </ul>
                          </div>
                       </nav>
                    </div>
                 </div>
              </div>
           </div>
        </div>

                </>
            ) : (
                <></>
            )}
        </div>
    );
}

export default Header;
