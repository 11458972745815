import React from 'react';
import { useSelector } from 'react-redux';

const BorderBottom = ({}) => {

    const borderbottom = useSelector(state => state.borderbottom.borderbottom);

    return (
    <div>

        <div className = "site-section  border-bottom">

            <div className = "container">

                <div className = "row">

                {
                    borderbottom.map(item=>(
                        <>
                        <div className="col-lg-4 col-md-6 mb-5 mb-lg-0">
                            <div className="media custom-media">
                                <div className="mr-3 icon with-shadow"> {/* Added class 'with-shadow' */}
                                <img src={item.icon} width="100" height="100" />
                                </div>
                                <div className="media-body">
                                <h5 className="mt-0">{item.title}</h5>
                                {item.description}
                                </div>
                            </div>
                        </div>
                        </>
                    ))
                }


                </div>

            </div>

        </div>

    </div>
    );
}



export default BorderBottom;