import React,  { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Url from '../Constants/Url';

const Footer = ({}) => {

    const [email, setEmail]=useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    const aboutsection = useSelector(state => state.aboutsection.aboutsection);

    const menus = useSelector(state => state.menus.menus);
    const borderbottom = useSelector(state => state.borderbottom.borderbottom);

    const sponsoring = useSelector(state => state.section.sponsoringSection);
    const items = useSelector(state => state.section.sponsoringItems);

    const sendMessage = async (e) => {
        e.preventDefault(); // Prevent form submission
        console.log(" " + email);

        if(email!==""){

            let msg = {
                personalizations: [
                  {
                    to: [{ email: 'contact@areus-developpement.com' }],
                    dynamic_template_data: {
                      // Fill in the dynamic template data as per your template's requirements
                      // For example:
                      email: `${email}`,
                    },
                  },
                ],
                from: { email: "contact@leadev.fr" },
                template_id: 'd-5411338345974e4bb5e924fa385bf891', // Replace with your template ID
              };
    
            await axios.post(`${Url.areus_Api}contact`, msg)
                .then(res => {
                    setEmail("");
                    setSuccess('Votre message a bien été envoyé. Merci !');
                    setTimeout(() => setSuccess(''), 5000); // Reset success message after 5 seconds
                })
                .catch(err => {
                    setEmail('');
                    setError("Une erreur est intervenue lors de l'envoi de votre message. Veuillez vérifier le formulaire.");
                    setTimeout(() => setError(''), 5000); // Reset error message after 5 seconds
                });
    
                let msg2 = {
                    personalizations: [
                      {
                        to: [{ email: email }],
                        dynamic_template_data: {
                          // Fill in the dynamic template data as per your template's requirements
                          // For example:
                          email: `${email}`,
                        },
                      },
                    ],
                    from: { email: 'contact@areus-developpement.com' },
                    template_id: 'd-04f7f75f6a0d4f1781446959317d59b0', // Replace with your template ID
                  };
        
                await axios.post(`${Url.areus_Api}contact`, msg2);

        }else{

            setError("Renseignez votre email svp");
            setTimeout(() => setError(''), 5000);
        }



    }


    return ( 
        <div>
            <footer className = "site-footer border-top">
                <div className = "container">

                    <div className = "row">

                        <div className = "col-lg-4 mb-5 mb-lg-0">
                            <div className = "row mb-5">
                                <div className = "col-12">
                                    <h3 className = "footer-heading mb-4">{aboutsection.subtitle}</h3>
                                    <p>{aboutsection.description}</p>
                                </div>
                            </div>
                            <div className = "row mb-5">
                                <div className = "col-md-12">
                                    <h3 className = "footer-heading mb-4"> Navigation </h3> </div> <div className = "col-md-6 col-lg-6">
                                    <ul className = "list-unstyled">
                                        {
                                            menus.map((item, index)=>(
                                                <li key={index}> <a href = "#"> {item.label} </a></li>
                                            ))
                                        }
                                    </ul>
                                </div>
                                <div className = "col-md-6 col-lg-6">
                                    <ul className = "list-unstyled">
                                        {
                                            borderbottom.map((item,index)=>(
                                                <li key={index}><a href = "#">{item.title}</a></li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    
                        <div className = "col-lg-4">


                            <div className = "mb-5">
                                <h3 className = "footer-heading mb-4">{sponsoring.subtitle}</h3>
                                <p className = "mb-5">
                                {sponsoring.baseline}
                                </p>
                                <div className = "block-25">
                                    <ul className = "list-unstyled">
                                    
                                    {
                                        items !== null && items.length >0 ?
                                        items.map((item,index)=>(

                                            <li className = "mb-3">
                                                <a href = "#"
                                                className = "d-flex">
                                                    <figure className = "image mr-4">
                                                    <img src ={item.image}
                                                    alt = ""
                                                    className = "img-fluid" />
                                                    </figure>
                                                    <div className = "text">
                                                        <span className = "small text-uppercase date">{item.label}</span>
                                                        <h3 className = "heading font-weight-light">{item.description}</h3>
                                                    </div>
                                                </a>
                                            </li>

                                        ))
                                        :
                                        <></>
                                    }
                                    </ul>
                                </div>
                            </div>

                        </div>


                        <div className = "col-lg-4 mb-5 mb-lg-0">

                            <div className = "mb-5">
                                
                                <h3 className = "footer-heading mb-2"> Souscrire à la Newsletter </h3>
                                <p>Saisissez votre adresse mail pour recevoir de nos nouvelles périodiquement.</p>

                                {success && <div className="alert alert-success">{success}</div>}
                                {error && <div className="alert alert-danger">{error}</div>}


                                <form action = "#" onSubmit={(e) => sendMessage(e)}
                                method = "post"
                                className = "form-subscribe">
                                    <div className = "input-group mb-3">
                                        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} 
                                        className = "form-control border-white text-white bg-transparent"
                                        placeholder = "Enter Email"
                                        aria-label = "Enter Email"
                                        aria-describedby = "button-addon2" />
                                        <div className = "input-group-append">
                                            <button className = "btn btn-primary"
                                            type = "submit"
                                            id = "button-addon2" >Envoyer</button>
                                        </div>
                                    </div>
                                </form>

                            </div>

                        </div>

                    </div>
                    
                    <div className = "row pt-5 mt-5 text-center">
                            <div className = "col-md-12">
                                <p>
                                Copyright & copy; <script> document.write(new Date().getFullYear()); </script> All rights reserved | <a href="https://leadev.fr "
                                target="_blank ">LEADEV</a>
                                </p>
                            </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;
