import React from 'react';
import Header from "../Components/header.js";
import Footer from "../Components/footer.js";
import Testimonials from "../Components/testimonials.js";
import Projects from "../Components/projects.js";
import Overlay from "../Components/overlay";
import BorderBottom from "../Components/borderbottom";
import AboutSection from "../Components/aboutsection";
import OurTeamSection from "../Components/ourteamsection";
import PricingSection from "../Components/pricingsection";
import FAQSection from "../Components/faqsection";
import NewsSection from "../Components/newssection";
import ServicesSection from "../Components/servicessection";
import ContactSection from "../Components/contactsection";
import { useSelector } from 'react-redux';

const Home = () => {

  const items = useSelector(state => state.section.sponsoringItems);

    return (
      <div >
        <div className = "site-wrap" >

        <Header />
        <Overlay />
        <BorderBottom />
        <AboutSection />
        <ServicesSection />
        <Projects />
        <NewsSection />
        <ContactSection />
        <Footer />

        </div>
        </div>
    );
}



export default Home;
