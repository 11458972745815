import React from 'react';
import { useSelector } from 'react-redux';

const AboutSection = ({}) => {

    const aboutsection = useSelector(state => state.aboutsection.aboutsection);

    return (
    <div>
        <div className = "site-section about-section border-bottom" id = "about-section">
            <div className = "container">
                <div className = "row align-items-center mb-5 pb-5">
                    <div className = "col-lg-7 img-years mb-5 mb-lg-0">
                        <img src={aboutsection.imageurl}
                        alt = "Image"
                        className = "img-fluid" />
                    </div>
                    <div className = "col-lg-4 ml-auto">
                        <span className = "sub-title"> {aboutsection.title} </span>
                        <h3 className = "mb-4"> {aboutsection.subtitle} </h3>
                        <p className = "mb-4"> {aboutsection.description} </p>
                        <p className = "text-left success mb-5" style={{display:'block'}}>
                            <span> {aboutsection.quality1} </span><br/>
                            <span> {aboutsection.quality2} </span><br/>
                            <span> {aboutsection.quality3} </span><br/>
                            <span> {aboutsection.quality4} </span><br/>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default AboutSection;