import { configureStore } from '@reduxjs/toolkit'
import headerReducer from '../Features/header.slice'
import linkReducer from '../Features/link.slice'
import menuReducer from '../Features/menu.slice'
import overlayReducer from '../Features/overlay.slice'
import borderbottomReducer from '../Features/borderbottom.slice'
import aboutsectionReducer from '../Features/aboutsection.slice'
import sectionReducer from '../Features/section.slice'


const reducer = {
  header : headerReducer,
  links : linkReducer,
  menus : menuReducer,
  overlay : overlayReducer,
  borderbottom : borderbottomReducer,
  aboutsection : aboutsectionReducer,
  section : sectionReducer
}

const store = configureStore({
  reducer: reducer,
  devTools: true,
})

export default store;
