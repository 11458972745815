import { createSlice } from "@reduxjs/toolkit";

export const borderbottomSlice = createSlice({
  name: "borderbottom",
  initialState:{
    borderbottom:[]
  },
  reducers:{
    setBorderBottom:(state, {payload})=>{
      console.log("setBorderBottom, borderbottom.slice.js");
      console.log(payload);
      state.borderbottom = payload;
      console.log(state.borderbottom);
    },
  },
});

export const { setBorderBottom } = borderbottomSlice.actions;
export default borderbottomSlice.reducer;
