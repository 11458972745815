import { createSlice } from "@reduxjs/toolkit";

export const menuSlice = createSlice({
  name: "menus",
  initialState:{
    menus:[]
  },
  reducers:{
    setMenus:(state, {payload})=>{
      console.log("setMenus, menu.slice.js");
      console.log(payload);
      state.menus = payload;
      console.log(state.menus);
    },
  },
});

export const { setMenus } = menuSlice.actions;
export default menuSlice.reducer;
